import * as actionTypes from '../actions/actionTypes';

import  { updateObject } from '../../shared/uitility';

const intialState = {
    data: [
        { 
        id: ' ',
        phonenumber: '050 0000000'
        }
    ]
};

const setCurrentData = (state, action) => {
    return updateObject(state, { data: action.data});
};



const reducer = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.SET_DATA: return setCurrentData(state, action);
        default:
            return state;      
    };
};

export default reducer;