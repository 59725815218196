import React, {Component} from 'react';

import './Contact.css'

class Contact extends Component{

    componentDidMount() {
        window.scrollTo(0, 0)
      }
    
    render(){

        return(
            <div className="Contact_Wrapper">
                <div className="contact_info">
                    <h1 className="desktoptitle">Yhteistyö</h1>
                    <div className="text_box">
                        <p>
                            Haluatko sinä tai edustamasi yritys olla esillä opiskeluvuoden kohokohtana? Radio Wapina hakee yhteistyökumppaneita tukemaan toimintaansa ja 
                            täyttämään kassakirstua, kun se samaan aikaan hupenee esimerkiksi erilaisiin lupahakemuksiin, lisensseihin ja radiokaluston kunnossapitoon. 
                            Radio Wapina tarjoaa yhteistyökumppaneilleen erinomaisen mahdollisuuden tavoittaa paikallinen opiskelijakansa yli ainejärjestö- ja korkeakoulurajojen. 
                            
                        </p>
                        <p>
                            Suunnittelemme ja toteutamme halutessanne radiomainokset toiveidenne mukaan ja takaamme että pilke silmäkulmassa tehdyt mainokset jäävät kuulijoiden 
                            korvakäytäviin vielä pitkäksi aikaa Wapun jälkeenkin. Yhteistyökumppaniksi voi lähteä jo pikkurahalla mutta räätälöimme mainospaketin sisällön juuri 
                            teidän toiveidenne mukaan. Mikäli haluatte yrityksenne logon nettisivuille ja/tai studion seinälle, päästä tekemään oma radioshow tai jotain ihan muuta, olethan rohkeasti yhteydessä!
                        </p>
                        <div className="text_box2">
                        <p className="Cursive">
                            Radio Wapina on vuonna 2014 perustettu, vuosittain toteutettava Wappuradio. Radion toteutuksesta vastaa vaasalaiset tekniikan opiskelijat, aina äänitekniikasta markkinointiin. 
                            Radio Wapina on kuultavissa joka Wappu n. 10 päivän ajan. Rennon vaihtoehtoviihteen äänenä toimivat kampuksen opiskelijat fukseista aina valmistuneihin asti. Studiomme sijaitsee 
                            Vaasan yliopiston kampuksella ja studion täysin uusitu laitteisto, äänitekniikka ja akustiikka on ensi kertaa käytössä Wappuna 2021 – tästä valtavan iso kiitos kuuluu jo olemassa 
                            oleville yhteistyökumppaneillemme, joita ilman tämän kokoluokan investointi ei olisi ollut mahdollista. 
                        </p>
                        <p className="Cursive">
                            Radion näkyvyyteen on viime vuosissa tapahtunut isoja askelia ja käyrä osoittaa onneksi vahvasti ylöspäin.  Wappuna 2020 lyötiin vanhat ennätykset rikki 14 000 kuuntelijalla ja 
                            3000 uniikilla kuuntelijalla. Kun otetaan huomioon, että Radio Wapinaa kuunnellaan yleensä suuremmalla porukalla yhden vastaanottimen äärellä, tämä on kova suoritus, josta käy 
                            kiittäminen radion mahtavia juontajia!
                        </p>

                        </div>

                        <div className="text_box2"> 
                        <p>
                            Kiinnostuitko? Hienoa, ollaanhan yhteydessä ja keskustellaan yksityiskohdista tarkemmin!
                        </p>
                        <p>
                        Sähköposti: <a href = "mailto: myynti@wapina.fi">myynti@wapina.fi</a>
                            
                        </p>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Contact;