import React from 'react';

import Logo from '../../Assets/Logos/WapinaApina.png'; 
import './WapinaLogo.css'

const background = (props) => (
    <div className="Logo">
        <img src={Logo} alt="Background"/>
    </div>
);

export default background; 