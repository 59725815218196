import React, { Component } from 'react';
import { connect } from 'react-redux';
import classes from './Player.module.css';

class Player extends Component{
    
    componentDidMount = () =>{
        this.play();
    }

    play = () => {
        let audio = document.getElementById("audio"); 
        if(this.props.play){
            audio.play();
        } else if(!this.props.play) {
            audio.pause();
        }
    }

    render() {
        return(
            <div className={classes.Player}>
                    <audio id="audio" controls className={classes.Audio}>
                        <source src="https://streamer.radio.co/sedeb39bb9/listen" type="audio/mpeg"/>
                        <source src="https://streamer.radio.co/sedeb39bb9/listen" type="audio/ogg"/>
                        Your browser does not support the audio element.
                    </audio> 
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
       play: state.play.play
    }
}



export default connect(mapStateToProps)(Player);