import React from 'react';

import classes from './CallStudio.module.css';

import { connect } from 'react-redux';

const callStudio = (props) => {
    return(
        <div className={classes.number}>
            <a href={'tel:' + props.information[0].phonenumber}>{props.information[0].phonenumber}</a>
 
        </div>
    ); 
};

const mapStateToProps = state => {
    return {
        information: state.information.data
    }
}


export default connect(mapStateToProps)(callStudio);