import React from 'react';

import './Footer.css';

import Twitter from '../../Assets/Logos/001-twitter.png';
import Facebook from '../../Assets/Logos/002-facebook.png';
import Instagram from '../../Assets/Logos/003-instagram.png';

const currentYear = new Date().getFullYear();

const footer = (props) => (
    <div className="footerWrapper">
        <div className="footer">
            <div className="footerBox_1">
                <h2>Ota yhteyttä</h2>
                <a href="/yhteystiedot">Yhteystiedot</a>
            </div>
            <div className="footerBox_1">
                <h2>Lisää</h2>
                <a href="/admin">Admin</a>
            </div>
            <div className="footerBox_2">
                <h2>Seuraa somessa</h2>
                <a href="https://www.facebook.com/RadioWapina"><img src={Facebook} alt=""></img></a>
                <a href="https://twitter.com/RadioWapina/"><img src={Twitter} alt=""></img></a>
                <a href="https://www.instagram.com/wapinaradio/"><img src={Instagram} alt=""></img></a>
            </div>
        </div>
        <div>
            <span>Copyright &copy; {currentYear} Wapina - Design by Eemil Vähä</span>
        </div>
    </div>
)

export default footer;