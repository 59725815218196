import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom'; 
import Layout from './hoc/Layout/Layout';
import { connect } from 'react-redux';
import Footer from './Navigation/Footer/Footer';
import './App.css';
import * as actions from './store/actions/index';

import Mainpage from './Containers/Mainpage/MainpageContainer';
import Contact from './Containers/Contact/ContactContainer';
import Juontaminen from './Containers/Content/Juontaminen';
import Info from './Containers/Content/Info';
import Admin from './Containers/Auth/Auth';
import Logout from './Containers/Auth/Logout/Logout';
import PopUpPlayer from './Components/PopupPlayer/Player';

class App extends Component{
  
  componentDidMount () {
    this.props.onTryAutoSignUp();
    
  }

  render() {
    
    let routes = (
      <Switch>
        <Route path="/admin" component={Admin} />
        <Route path="/podcast" component={Info} />
        <Route path="/juontaminen" component={Juontaminen} />
        <Route path="/yhteystiedot" component={Contact} />
        <Route path="/logout" component={Logout} />
       <Route path="/" exact component={Mainpage}/> 
      </Switch>
  )
  
  return (
    <div className="App">
      <div className="page_wrapper">
          <BrowserRouter>
              <Layout>
                  {routes} 
              </Layout> 
          </BrowserRouter>
    </div>
    {this.props.playRadio ? <PopUpPlayer/> : <div></div>}
    <Footer/>
    </div>
  );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null,
    playRadio: state.play.play
  };
};

 const mapDispatchToProps = dispatch => {
   return {
     onTryAutoSignUp: () => dispatch(actions.authCheckState())
   };
 };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
