import React, {Component} from 'react';

import './Content.css'

class Juontaminen extends Component{

    componentDidMount() {
        window.scrollTo(0, 0)
      }
    
    render(){

        return(
            <div className="Content_Wrapper">
                <div className="content_box">
                    <h1>Juontajaksi Radio Wapinaan?</h1>
                    <div className="text_box">
                        <p>
                            Oletko oman elämäsi tähti? Onko sinulla idea ohjelmaksi? Mitä tarkoittaa juontaa radio ohjelmaa vappuradiossa? Oletko radioammattilanen vai 
                            radioamatööri? Meillä radio Wapinassa pääset kokeilemaan radio-ohjelman tekoa ja juontamista, ammattitasoisella nettiradio alustalla.
                        </p>
                        <p>
                            Radio-ohjelmassa juonnat ja operoit 2-3h ohjelman livenä osoitteessa wapina.fi. Studion mikkien ääreen mahtuu kerralla 1-4 ihmistä, joista yksi 
                            operoi musiikkitarjontaa. Show voi sisältää ajankohtaisia aiheita, pieneen musiikkigenreen tutustumista tai agendana voi olla vaikka interaktiivinen
                            kommunikointi kuuntelijoiden kanssa. Vain radioaallot ovat rajana mielikuvitukselle!
                        </p>
                        <p>
                            Ohjelman ideoimiseen ja tekemiseen löytää paljon apua tutustumalla kaupallisten radioiden toimintaan tai perinteisesti googlettamalla. 
                            Kahden tunnin slotti saattaa kuulostaa pitkältä, mutta tulet huomaamaan kuinka radioaalloilla aika menee kuin siivillä. 
                            <span className="bold"> Radion tekeminen ei vaadi minkään tasoista teknistä osaamista.</span> Toimintaan annetaan riittävä perehdytys ja studiohenkilökunta on poron kuseman päässä auttamassa!
                        </p>
                        <h2>Musiikin soittaminen</h2>
                        <p>
                            Radio Wapina omistaa vaadittavat luvat ja lisenssit musiikin asianmukaiseen soittamiseen. Radiolla on alati kasvava, tuhansien biisien musiikkivarasto. 
                            Voit myös soittaa omaa musiikkia, jos sinulla on fyysinen tai digitaalinen todiste siitä että olet itse hankkinut sen. Myös oman vinyylilevysoittimen 
                            tai muun härpäkkeen yhdistäminen on järjestelmään mahdollista. 
                        </p>
                        <p>
                            Jos haluat tuoda omaa musiikkia tai soittaa jotain erityistä, ole yhteydessä Wapinan henkilökuntaan vähintään pari viikkoa ennen vappuradion starttia.
                        </p>
                        <p className="bold">
                            Tervetuloa radioon! 
                        </p>

                    </div>
                </div>
            </div>
        );
    }
}

export default Juontaminen;