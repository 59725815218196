export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const PLAY = 'PLAY';
export const SET_PROGRAM = 'SET_PROGRAM';
export const SET_DATA = 'SET_DATA';


