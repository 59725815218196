import React from 'react';

import classes from './WarningPopup.module.css';
import ContinueButton from '../../UI/Buttons/ContinueButton';
import CancelButton from '../../UI/Buttons/CancelButton';
import Backdrop from '../../UI/Backdrop/BackdropBlack';

const popup = (props) => (
    <div>
    <Backdrop show={true}/>
    <div className={classes.popup}>
        <div className={classes.message_section}>
            <h2>Haluatko varmasti poistaa tämän?</h2>
        </div>

        <div className={classes.button_section}>
            <ContinueButton id="yes" click={props.continue}>Kyllä</ContinueButton>
            <CancelButton id="no" click={props.cancel}>Peruuta</CancelButton>
        </div>
    </div>
    </div>
    
)

export default popup;