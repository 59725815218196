import React, { Component } from 'react';

import './NavigationItems.css';
import NavigationItem from './NavigationItem/NavigationItem';
import Logout from '../../UI/Buttons/SettingsButton3';
import Twitter from '../../Assets/Logos/001-twitter.png';
import Facebook from '../../Assets/Logos/002-facebook.png';
import Instagram from '../../Assets/Logos/003-instagram.png';
import CallStudio from '../../Components/Call/CallStudio';


class NavigationItems extends Component {



    render(){
        let content = (
            <ul className="NavigationItems">
                <NavigationItem link="/"  exact>Etusivu</NavigationItem>
                <NavigationItem link="/juontaminen">Juontajille</NavigationItem>
                <NavigationItem link="/yhteystiedot">Yhteistyökumppaneille</NavigationItem>
                <NavigationItem link="/podcast">Podcast</NavigationItem>
                <CallStudio/>
                    <a href="https://www.facebook.com/RadioWapina"><img src={Facebook} alt=""></img></a>
                    <a href="https://twitter.com/RadioWapina/"><img src={Twitter} alt=""></img></a>
                    <a href="https://www.instagram.com/wapinaradio/" className="last_element"><img src={Instagram} alt=""></img></a> 
                { this.props.isAuthenticated ? <Logout className="logout" link="/logout">Kirjaudu ulos</Logout> : <div></div>}
            </ul>
            );
        

        return (
            <div>
                {content}
            </div>
        );
    }
};



export default NavigationItems;