import React, { Component } from 'react';

import classes from './InfoPopup.module.css';
import SettingsButton from '../../UI/Buttons/SettingsButton';
import SettingsIcon from '../../Assets/Logos/001-settings.png'
import DropdownButton from '../../UI/Buttons/DropdownButton';
import Backdrop from '../../UI/Backdrop/Backdrop';
import axios from '../../axiosdata';
import { connect } from 'react-redux';
import EditProgram from './EditProgram';
import WarningPopup from '../Controls/WarningPopup';

class InfoPopup extends Component {

    state = { 
        showDropdownSettings: false,
        showEditProgramPopup: false,
        showWarningPopup: false
    }

    getWeekDay = (date) => {
        const days = ['Sunnuntai', 'Maanantai', 'Tiistai', 'Keskiviikko', 'Torstai', 'Perjantai', 'Lauantai'];
        const weekDay = new Date(date).getDay();
        return days[weekDay];
    };
    
    showDropDown = () => {
        this.state.showDropdownSettings ? this.setState({showDropdownSettings: false}) : this.setState({showDropdownSettings: true})
    }
    showEditProgram = () => {
        this.state.showEditProgramPopup ? this.setState({showEditProgramPopup: false}) : this.setState({showEditProgramPopup: true})
        this.setState({showDropdownSettings: false})
    }

    showWarningPopup = () => {
        this.state.showWarningPopup ? this.setState({showWarningPopup: false}) : this.setState({showWarningPopup: true})
        this.setState({showDropdownSettings: false})
    }

    deleteProgram = (id) => {
        axios.delete(`/programs/${id}.json?auth=` + this.props.token)
        .then(res => window.location.reload(false))
        .catch(err => console.log(err)); 
    }
    compareToCurrentDate = (date) => {
        let name ='';
        let compareDate = new Date(date);
        let currentDate = new Date();
        if(currentDate.toDateString() === compareDate.toDateString()){
            name = 'currentDay';
        } 
        return name;
    }

    render() {

        return (
            <div className={classes.popup} onClick={this.props.click}>
                {this.props.isAuthenticated ? 
                    <div className={classes.delete}>
                        <SettingsButton click={this.showDropDown}>
                            <img src={SettingsIcon} alt=""/>
                        </SettingsButton>
                    </div> 
                : null}
                <div className={[classes.header, classes[this.compareToCurrentDate(this.props.program.program.date)]].join(' ')}>
                    <h2>{this.getWeekDay(this.props.program.program.date)}</h2>
                    <h2>{this.props.program.program.date.slice(8,10)}.{this.props.program.program.date.slice(5,7)}</h2>
                </div>
                <div className={classes.body}>
                    <h3>Aika: </h3>
                    <h4>{this.props.program.program.startTime}-{this.props.program.program.end_time}</h4>
                    <h3>Ohjelman nimi:</h3>
                    <h4>{this.props.program.program.programName}</h4>
                    <h3>Ryhmän nimi:</h3>
                    <h4>{this.props.program.program.groupName}</h4>
                    <h3>Lisätietoa: </h3>
                    <h4>{this.props.program.program.info}</h4>
                </div>
                {this.state.showDropdownSettings ? 
                <div className={classes.dropdown}>
                    <div className={classes.dropdownElement}>
                            <DropdownButton click={this.showEditProgram}>Muokkaa</DropdownButton>
                            <DropdownButton click={this.showWarningPopup}>Poista</DropdownButton>
                    </div>
                    <Backdrop show={this.state.showDropdownSettings} clicked={this.showDropDown}/>
                </div>
                : null}
                {this.state.showEditProgramPopup ? 
                <div>
                <EditProgram close={this.showEditProgram} program={this.props.program}/>
                </div>
                : null}
                {this.state.showWarningPopup ? 
                <div>
                <WarningPopup cancel={this.showWarningPopup} continue={()=>this.deleteProgram(this.props.program.id)}/>
                </div>
                : null}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        isAuthenticated: state.auth.token !== null,
    }
}


export default connect(mapStateToProps)(InfoPopup);