import React, { Component } from 'react';
import { connect } from 'react-redux';

import classes from './phonenumber.module.css';
import axios from '../../axiosdata';
import Button from '../../UI/Buttons/Button'

class InformationEditor extends Component {

    state = {
        id: this.props.information[0].id,
        phonenumber: this.props.information[0].phonenumber
    }

    setNewNumber = () => {
        const data = {
            phonenumber: this.state.phonenumber
        }
        axios.put(`/information/${this.state.id}.json?auth=` + this.props.token, data)
        .then(res => window.location.reload(false))
        .catch(err => console.log(err));
    }


    handleChange = (event) => {
        this.setState({phonenumber: event.target.value});
    }


    render(){
        return(
            <div className={classes.infoContainer}>
                <h3>Puhelinnumero</h3>
                <input type="text" value={this.state.phonenumber} onChange={this.handleChange}/> 
                <Button click={this.setNewNumber}>Tallenna</Button>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        information: state.information.data
    }
}

const mapDispatchToProps = dispatch => {
    return{
        
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(InformationEditor);