import React from 'react';

import classes from './Button.module.css';
import GoogleGIcon from '../../Components/Icons/GoogleGIcon'

const button = (props) => (
    <button
    disabled={props.disabled}
    className={classes.GoogleButton}
    onClick={props.click}>
            <GoogleGIcon/>    
            {props.children}
     
    </button>
    
)

export default button;