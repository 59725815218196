import React from 'react';

import './Navbar.css';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import NavigationItems from '../NavigationItems/Navigationitems';


const navbar = (props) => (
    <header className="Navbar">
     
        <DrawerToggle clicked={props.drawerToggleClicked}/>
        <div className="Logo2">
        </div>
        <nav className="DesktopOnly">
            <NavigationItems isAuthenticated={props.isAuth}/>
        </nav>
    </header>
);

export default navbar;