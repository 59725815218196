import React, { Component } from 'react';

import classes from './Calendar.module.css';

import { connect } from 'react-redux';
import axios from '../../axiosdata';
import * as actions from '../../store/actions/index';

import InfoPopup from './InfoPopup';
import BackdropBlack from '../../UI/Backdrop/BackdropBlack';
import NextButton from '../../UI/Buttons/NextButton';
import PrevButton from '../../UI/Buttons/PrevButton';

let nextProgram = null;

class Calendar extends Component{

    state = {
        programs: null,
        content: <div></div>,
        differentDaysArray: null,
        arrayOfDayArrays: null,
        finalSortedArray: null,
        arrayOfArraysWith5: null,
        arrayOfArraysOf5WithContent: null,
        times: null,
        showInfoPopup: false,
        program: null,
        mapArrayDesktop: null,
        showArray: 0,
        displayArray: [],
        currentProgram: {
            programName: 'Ei ohjelmaa',
            groupName: 'Katso ohjelmakalenteri',
            date: ' ',
            startTime: ' ',
            end_time: ' ',
            info: ' '
        }
    }

  
    componentDidMount = () => {
        this.getData(); 
    }



    setShowArrayIndexAccordingToDate = () => {
        let date = new Date();

        if(date.getDay() >= 4 && date.getDay() <= 6){
            this.setState({showArray: 1})
        } 
    }

    setCurrentProgram = () =>{
        this.props.onChange(this.state.currentProgram)
    }

    getData = () => {
        const programs = []; 
        axios.get('/programs.json')
        .then(res => {
            for (let key in res.data){
                programs.push({
                    ...res.data[key],
                    id: key,
                });
            }
            this.setState({programs: programs});
            this.orderData();
        })
        .catch(err => console.log(err));    
    }

    orderData = () => {
        let programs = this.state.programs;
        let sortedData = programs.sort(function(a,b){
            return new Date(a.program.date).getTime() - new Date(b.program.date).getTime();
          });
        this.setState({programs: sortedData})
        this.getDays();
    }

    getDays = () => {
        let differentDaysArray = [];
        let programs = this.state.programs;
        for(let i=0; i < programs.length - 1; i++){
            if(programs[i].program.date !== programs[i+1].program.date){
                differentDaysArray.push(programs[i].program.date)
            }
        }
        if(programs[programs.length-1].program.date !== programs[programs.length - 2].program.date){
            differentDaysArray.push(programs[programs.length-1].program.date)
        } else {
            differentDaysArray.push(programs[programs.length-2].program.date)
        }
        this.setState({differentDaysArray: differentDaysArray});
        this.createDayArrays();
    }
    
    createDayArrays = () => {
        let differentDaysArray = this.state.differentDaysArray;
        let arrayOfDayArrays = [];
        for(let i = 0; i < differentDaysArray.length; i++) {
            arrayOfDayArrays.push([]);
        }
        this.setState({arrayOfDayArrays: arrayOfDayArrays});
        this.createDayArraysWithContent();
    }

    createDayArraysWithContent = () => {
        let arrayOfDayArrays = this.state.arrayOfDayArrays;
        let programs = this.state.programs;
        arrayOfDayArrays[0].push(programs[0]);
        let y = 0;
        for(let i=0; i < programs.length-1; i++){
            if( programs[i+1].program.date === programs[i].program.date){
                arrayOfDayArrays[y].push(programs[i+1])
    
            } else {
                y++;
                arrayOfDayArrays[y].push(programs[i+1])
            }
        }
        this.setState({arrayOfDayArrays: arrayOfDayArrays})
        this.sortDayArrayswithContent();
    }

    sortDayArrayswithContent = () => {
        let dayArraysWithContent = this.state.arrayOfDayArrays;
        let finalSortedArray = [];
        for(let i=0; i < dayArraysWithContent.length; i++){
            finalSortedArray.push(
                dayArraysWithContent[i].sort(function(a,b){
                      return parseInt(a.program.startTime.slice(0, 2)) - parseInt(b.program.startTime.slice(0, 2));
                    })
            )
        }
        this.setState({finalSortedArray: finalSortedArray})
        this.createArraysThatFit5();
    }

    createArraysThatFit5 = () =>{
        let finalSortedArray = this.state.finalSortedArray;
        let arrayOfArraysWith5 = [];
        let length = (finalSortedArray.length)/5;

        for(let i = 0; i < Math.ceil(length); i++) {
            arrayOfArraysWith5.push([]);
        }

        this.setState({arrayOfArraysWith5: arrayOfArraysWith5})
        this.divideFinalArrayToArraysWith5();
    }


    divideFinalArrayToArraysWith5 = () => {
        let finalSortedArray = this.state.finalSortedArray;
        let arrayOfArraysOf5WithContent = this.state.arrayOfArraysWith5;
        for(let i=0; i < arrayOfArraysOf5WithContent.length; i++){
            for(let j= 5*i; j < (5*i)+5; j++){
                if(j >= finalSortedArray.length){
                    break;
                } 
                arrayOfArraysOf5WithContent[i].push(finalSortedArray[j])
            }
        }
        this.setState({arrayOfArraysOf5WithContent: arrayOfArraysOf5WithContent})
        this.createMapArrays();
        this.getCurrentProgram();
    }

    createMapArrays = () => {
        let array = this.state.arrayOfArraysOf5WithContent;
        let mapArrayDesktop = [];
        for(let i = 0; i < array.length; i++) {
            mapArrayDesktop.push([]);
        }
        this.setState({mapArrayDesktop: mapArrayDesktop});
        this.createFinalMapArrayDesktop();
    }

    createFinalMapArrayDesktop = () => {
        let array = this.state.arrayOfArraysOf5WithContent;
        let mapArrayDesktop = this.state.mapArrayDesktop;
        for(let i=0; i < mapArrayDesktop.length; i++){
            mapArrayDesktop[i].push(array[i]);
        }
        this.setState({mapArrayDesktop: mapArrayDesktop});
        this.mapData(this.state.showArray)
    }

/*     addEmptyProgramsToEmptySlots = () => {

        let emptyObject = {
            id: '0',
            program: {
                programName: ' ',
                groupName: ' ',
                date: ' ',
                startTime: ' ',
                end_time: ' ',
                info: ' '
            }
        }
        let array = this.state.arrayOfArraysOf5WithContent;
        
        for(let i=0; i < array.length; i++){
            for(let j=0; j < array[i].length; j++){
                for(let k=0; k < array[i][j].length-1; k++){
                    if ((parseInt(array[i][j][k].program.end_time) - parseInt(array[i][j][k+1].program.startTime)) === -1 ){
                        array.splice(k+1, 0, emptyObject)
                    } 
                }
            }
        }
    } */
 

    getWeekDay = (date) => {
        const days = ['Sunnuntai', 'Maanantai', 'Tiistai', 'Keskiviikko', 'Torstai', 'Perjantai', 'Lauantai'];
        const weekDay = new Date(date).getDay();
        return days[weekDay];
    };
    


    calculateHeight = (startTime, endTime) => {
        const timeDiff = parseInt(endTime.slice(0, 2)) - parseInt(startTime.slice(0, 2));
        const heightMap = {
            1: 'onehour',
            2: 'twohours',
            3: 'threehours',
            4: 'fourhours',
            5: 'fivehours',
            6: 'sixhours',
        };
        return heightMap[timeDiff] || null;
    };

    openInfoPopup = (program) => {
        this.state.showInfoPopup ? this.setState({showInfoPopup: false}) : this.setState({showInfoPopup: true});
        this.setState({program: program})
    }

    compareToCurrentDate = (date) => {
        let name ='';
        let compareDate = new Date(date);
        let currentDate = new Date();
        if(currentDate.toDateString() === compareDate.toDateString()){
            name = 'currentDay';
        } 
        return name;
    }

    getCurrentProgram = () => {
        let array =  this.state.programs
        let currentDate = new Date();
        let programStart = new Date();
        let programEnd = new Date();


        for (let i=0; i < array.length; i++) {
    /*         if((currentDate.toDateString() === new Date(array[i].program.date).toDateString()) 
            && (currentDate.getHours() >= parseInt(array[i].program.startTime)) 
            && (currentDate.getHours() < parseInt(array[i].program.end_time))
            && (currentDate.getMinutes() >= parseInt(array[i].program.startTime.slice(3,5)))
            ){
            
                this.setState({currentProgram: array[i].program})
            } */

                if(
                    (currentDate.toDateString() === new Date(array[i].program.date).toDateString()) &&
                    (currentDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0) >= programStart.setHours(parseInt(array[i].program.startTime),parseInt(array[i].program.startTime.slice(3,5)), 0))
                    && (currentDate.setHours(currentDate.getHours(), currentDate.getMinutes(), 0) < programEnd.setHours(parseInt(array[i].program.end_time),parseInt(array[i].program.end_time.slice(3,5)), 0))
                    ){
                        this.setState({currentProgram: array[i].program})
                    }
                }
            
                this.setCurrentProgram();
    }

    next = () => {
        if(this.state.showArray < (this.state.mapArrayDesktop.length - 1)){
            this.mapData(this.state.showArray + 1);
            this.setState({showArray: this.state.showArray +1})
        }
    }

    previous = () => {
        if(this.state.showArray > 0){
            this.mapData(this.state.showArray - 1);
            this.setState({showArray: this.state.showArray - 1})
        } 
    }

    breakString = (str, limit) => {
        let brokenString = '';
        for(let i = 0, count = 0, mcount = 0; i < str.length; i++){
            if(str[i] === 'M' || str[i] === 'm' ){
              mcount++;
            } 
            if(mcount >= 18){
              mcount = 0;
              count = 0;
              brokenString += '\n';
            }
            if(str[i] === ' ' && count < limit){
               count = 0;
            }
            if(count >= limit){
              count = 0;
              brokenString += str[i] + "-" +'\n';
            }
            else{
              count++;
              brokenString += str[i];
           }
        }
        return brokenString;
     }

    
    mapData = (arrayIndex) => {
        
        let content = (
            <div className={classes.calendarWrapper}>
                {this.state.mapArrayDesktop[arrayIndex].map((calendar, index) => (
                <div className={classes.calendar} key={index + Math.random() * 1000}>
                    <div className={classes.calendarInner} key={index + Math.random() * 1000}>
                        {calendar.map((day, index) => (
                                <div className={classes.day} key={index + Math.random() * 1000}>
                                    <div className={classes.header2} key={index + Math.random() * 1000}>
                                    
                                    </div>
                                <div className={[classes.header, classes[this.compareToCurrentDate(day[0].program.date)]].join(' ')}>
                                    <h2>{this.getWeekDay(day[0].program.date)}</h2>
                                    <h2>{day[0].program.date.slice(8,10)}.{day[0].program.date.slice(5,7)}</h2>
                                </div>
                                {day.map((program,index) => (
                                    <div className={[classes.program, classes[this.calculateHeight(program.program.startTime, program.program.end_time)]].join(' ')} onClick={()=>this.openInfoPopup(program)} key={index + Math.random() * 1000}>
                                        <h5>{program.program.startTime}-{program.program.end_time}</h5>
                                        <h3>
                                            {program.program.programName}
                                        </h3>
                                        <h3>
                                            {this.calculateHeight(program.program.startTime, program.program.end_time) === "onehour" ?  ' ' : program.program.groupName}
                                        </h3>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                    </div>
                ))}
            </div>
        )
    
        this.setState({content: content})
    }

    render() {
        let popup = null;
        if(this.state.showInfoPopup) {
            popup = (
               <div><InfoPopup program={this.state.program} isAuthenticated={this.props.isAuthenticated}/><BackdropBlack show={this.state.showInfoPopup} clicked={this.openInfoPopup}/></div>
            )
        }
        return(
            <div>
                <div className={classes.desktop}>
                    <PrevButton click={this.previous}>Edellinen</PrevButton>
                    <NextButton click={this.next}>Seuraava</NextButton>
                </div>
                {popup}
                {this.state.content}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
        isAuthenticated: state.auth.token !== null,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onChange: (program) => dispatch(actions.setCurrentProgram(program))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(Calendar);