import React, {Component} from 'react';

import { connect } from 'react-redux';
import './Mainpage.css'
import Logo from '../../Components/Logos/WapinaLogo';
import Player from '../../Components/Player/Palyer';
import Shoutbox from '../../Components/Shoutbox/Shoutbox';
import axios from '../../axiosdata';
import DeleteButton from '../../UI/Buttons/EditButton';
import AdminSettingsDropdown from '../../Components/Controls/AdminSettingsDropdown';
import ImageUploader from '../../Components/Sponsors/ImageUploader';
import BackdropBlack from '../../UI/Backdrop/BackdropBlack'
import DataUploader from '../../Components/Calendar/DataUploader';
import Caledar from '../../Components/Calendar/Calendar';
import CaledarMobile from '../../Components/Calendar/CalendarMobile';
import InformationEditor from '../../Components/Information/InformationEditor';
import * as actions from '../../store/actions/index';


class Mainpage extends Component{

    state = {
        showShoutbox: null,
        showSponsorsSettingsDropDown: false,
        showShoutboxSettingsDropDown: false,
        showCalendarSettingsDropDown: false,
        sponsors: null,
        sponsorsElement: <div></div>,
        showDeleteOptions: false,
        showAddOptions: false,
        id: 'show',
        showAddProgram: false,
        informationData: null
    }

    componentDidMount() {
        this.getdata();
        this.getSponsors();
        this.getInformation();
        window.scrollTo(0, 0);
    }
 
    getdata = () => {

        axios.get('/shoutbox.json')
        .then(res => {
            this.setState({showShoutbox: res.data.show.show})
        })
        .catch(err => console.log(err));     
    }

    /* Haetaan firebasesta sponsorit */

    getSponsors = () => {
        const sponsors = []; 
        axios.get('/sponsors.json')
        .then(res => {
            for (let key in res.data){
                sponsors.push({
                    ...res.data[key],
                    id: key,
                });
            }
            this.setState({sponsors: sponsors});
            this.mapSponsors();
        })
        .catch(err => console.log(err));        
    }
    
    /* Haetaan firebasesta tietoja tässätapauksessa puhelinumero ja jaetaan tieto reducerin kautta muille komponenteille */

    getInformation = () => {
        const data = []; 
        axios.get('/information.json')
        .then(res => { 
            for (let key in res.data){
                data.push({
                    ...res.data[key],
                    id: key,
                });
            }
            this.setState({informationData: data})
            this.props.setData(data)
        })
        .catch(err => console.log(err));     
    }

    /* Shoutboxin sulku ja avaus toiminto, muuttaa yhtä atribuuttia firebase tietokannssa */

    openShoutbox = () => {
        const data = {
            show: true
        }
        axios.put(`/shoutbox/${this.state.id}.json?auth=` + this.props.token, data)
        .then(res => this.setState({showShoutbox: true}))
        .catch(err => console.log(err));
        
        this.state.showShoutboxSettingsDropDown ? this.setState({showShoutboxSettingsDropDown: false}) : this.setState({showShoutboxSettingsDropDown: true});
    }

    closeShoutbox = () => {
        const data = {
            show: false
        }
        axios.put(`/shoutbox/${this.state.id}.json?auth=` + this.props.token, data)
        .then(res => this.setState({showShoutbox: false}))
        .catch(err => console.log(err));
        
        this.state.showShoutboxSettingsDropDown ? this.setState({showShoutboxSettingsDropDown: false}) : this.setState({showShoutboxSettingsDropDown: true});
    }

    /* Toimto sponsoreiden poistoon firebase tietokannasta */

    deleteSponsor = (elementId) => {
        axios.delete(`/sponsors/${elementId}.json?auth=` + this.props.token)
        .then(res => window.location.reload(false))
        .catch(err => console.log(err));
    }

    /* Mapping funktio sponsori elementeille, jokaiselle sponsorielementiin liitetään linkki */

    mapSponsors = () => {
        let sponsorsElementUnAuth  = (
            <div className="logos_container" key = {Math.random() * 10000000}>
               {this.state.sponsors.map((sponsor, index) =>(
                    <div className="logo_element">
                        <a href={sponsor.link.link} key = {index + Math.random() * 1000}>  
                            <img src={sponsor.image} alt=""/>
                        </a>
                    </div>
                        
               ))}
           </div> 
        );
        let sponsorsElementAuth  = (
            <div className="logos_container">
               {this.state.sponsors.map((sponsor, index) =>(
                    <div className="logo_element" key = {Math.random() * 10000000}>
                        <a href={sponsor.link.link} key = {index + Math.random() * 1000}>  
                            <img src={sponsor.image} alt=""/>
                        </a>
                        <div className="delete_button">
                           <DeleteButton click={() => this.deleteSponsor(sponsor.id)}>X</DeleteButton>
                        </div>
                    </div>
                        
               ))}
           </div> 
        );
        this.setState({sponsorsElementUnAuth: sponsorsElementUnAuth});
        this.setState({sponsorsElementAuth: sponsorsElementAuth});
    }

    /* Asetust toimintojen hallintaa */

    changeSettingsState = (stateToChange) => {
        if(stateToChange === 'showSponsorsSettingsDropDown'){
            this.state.showSponsorsSettingsDropDown ? this.setState({showSponsorsSettingsDropDown: false}) : this.setState({showSponsorsSettingsDropDown: true});
        }
        if(stateToChange === 'showShoutboxSettingsDropDown'){
            this.state.showShoutboxSettingsDropDown ? this.setState({showShoutboxSettingsDropDown: false}) : this.setState({showShoutboxSettingsDropDown: true});
        }
        if(stateToChange === 'showCalendarSettingsDropDown'){
            this.state.showCalendarSettingsDropDown ? this.setState({showCalendarSettingsDropDown: false}) : this.setState({showCalendarSettingsDropDown: true});
        }
    }

    showDeleteOptions = () => {
        this.state.showSponsorsSettingsDropDown ? this.setState({showSponsorsSettingsDropDown: false}) : this.setState({showSponsorsSettingsDropDown: true});
        this.state.showDeleteOptions ? this.setState({showDeleteOptions: false}) : this.setState({showDeleteOptions: true});
    }
    showAddOptions = () => {
        this.state.showSponsorsSettingsDropDown ? this.setState({showSponsorsSettingsDropDown: false}) : this.setState({showSponsorsSettingsDropDown: true});
        this.state.showAddOptions ? this.setState({showAddOptions: false}) : this.setState({showAddOptions: true});
    }
    closeShowAddOptions = () => {
        this.setState({showSponsorsSettingsDropDown: false});
        this.setState({showAddOptions: false});
    }
    showAddProgram = () => {
        this.state.showCalendarSettingsDropDown ? this.setState({showCalendarSettingsDropDown: false}) : this.setState({showCalendarSettingsDropDown: true});
        this.state.showAddProgram ? this.setState({showAddProgram: false}) : this.setState({showAddProgram: true});
    }
    closeAddProgram = () => {
        this.setState({showCalendarSettingsDropDown: false});
        this.setState({showAddProgram: false});
    }
    closeShowAddProgram = () => {
        this.setState({showCalendarSettingsDropDown: false})
        this.setState({showAddProgram: false})
    } 

    render(){
        return(
            <div className="Mainpage_Wrapper">
                <div className="tittle_container">
                    <div className="logo_container">
                        <Logo/>
                        <h1>Radio Wapina</h1>
                        <span>Väriterapiaa korvakäytävillesi</span>
                      
                    </div>
                    <div className="player">
                        <Player/>
                    </div>
                    <div className="triangle2"></div>
                    <div className="triangle"></div>
                </div>
                

            <div>
            </div>
               
                 

                {/* ----------------------------- Sponsors ------------------------------------ */}

                {this.props.isAuthenticated ? <div className="section_separator"></div>: null}
                <div className="sponsor">
                    {this.props.isAuthenticated ? 
                    <AdminSettingsDropdown showSponsorsSettings={this.state.showSponsorsSettingsDropDown} click={() => this.changeSettingsState("showSponsorsSettingsDropDown")} delete={this.showDeleteOptions} add={this.showAddOptions}/>
                    : null}
                    <h1>Menossa mukana</h1>
                    {!this.props.isAuthenticated ? this.state.sponsorsElementUnAuth : null} 
                    {(this.props.isAuthenticated && !this.state.showDeleteOptions) ? this.state.sponsorsElementUnAuth : null} 
                    {(this.props.isAuthenticated && this.state.showDeleteOptions) ? this.state.sponsorsElementAuth : null}
                    {(this.props.isAuthenticated && this.state.showAddOptions) ? 
                    <div>
                        <BackdropBlack show={this.state.showAddOptions} clicked={this.closeShowAddOptions}/>
                        <ImageUploader/>
                    </div>
                    : null}
                </div>

               {/* ----------------------------- Sponsors ends ------------------------------------ */}

               {/* ----------------------------- Shoutbox ------------------------------------ */}

                {this.props.isAuthenticated ? <div className="section_separator"></div>: null}
                <div className="Content_2"> 
                    <h1>Shoutbox</h1>
                    <Shoutbox isAuthenticated={this.props.isAuthenticated} showShoutbox={this.state.showShoutbox} />
                {this.props.isAuthenticated ?
                <AdminSettingsDropdown showShoutboxSettings={this.state.showShoutboxSettingsDropDown} click={() => this.changeSettingsState("showShoutboxSettingsDropDown")} open={this.openShoutbox} close={this.closeShoutbox}/>
                : null}
                </div>
                

                {/* ----------------------------- Shoutbox ends -------------------------------- */}
                
                {/* ----------------------------- Calendar Desktop-------------------------------- */}


                {this.props.isAuthenticated ? <div className="section_separator"></div>: null}
                <div className="calendar"> 
                    <h1>Ohjelmakalenteri</h1>
                {this.props.isAuthenticated ?
                <AdminSettingsDropdown showCalendarSettings={this.state.showCalendarSettingsDropDown} click={() => this.changeSettingsState("showCalendarSettingsDropDown")} addProgram={this.showAddProgram}/>
                : null}
                {(this.props.isAuthenticated && this.state.showAddProgram) ? 
                    <div>
                        <BackdropBlack show={this.state.showAddProgram} clicked={this.closeShowAddProgram}/>
                        <DataUploader cancel={this.closeAddProgram}/>
                    </div>
                    : null}
                    <Caledar/>
                </div>
                    
                {/* ----------------------------- Calendar ends -------------------------------- */}

                {/* ----------------------------- Calendar Mobile-------------------------------- */}


                <div className="calendarMobile"> 
             
                    <h1>Ohjelmakalenteri</h1>
                {this.props.isAuthenticated ?
                <AdminSettingsDropdown showCalendarSettings={this.state.showCalendarSettingsDropDown} click={() => this.changeSettingsState("showCalendarSettingsDropDown")} addProgram={this.showAddProgram}/>
                : null}
                {(this.props.isAuthenticated && this.state.showAddProgram) ? 
                    <div>
                        <BackdropBlack show={this.state.showAddProgram} clicked={this.closeShowAddProgram}/>
                        <DataUploader cancel={this.closeAddProgram}/>
                    </div>
                    : null}
                    <CaledarMobile/>
                </div>
                    
                {/* ----------------------------- Calendar ends -------------------------------- */}

                {/* ----------------------------- Information start -------------------------------- */}

                {this.props.isAuthenticated ? <InformationEditor/> : null}

                {/* ----------------------------- Information ends -------------------------------- */}
               

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        token: state.auth.token,
        currentProgram: state.play.program,
        information: state.information.data
    }
}

const mapDispatchToProps = dispatch => {
    return{
        setData: (data) => dispatch(actions.setData(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Mainpage);