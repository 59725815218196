import React, { Component } from 'react';
import { connect } from 'react-redux';

import classes from './InformationEditor.module.css';
import * as actions from '../../store/actions/index';
import AdminSettings from '../Controls/AdminSettings';

class InformationEditor extends Component {

    state = {
        data: '',
        showPhonenumber: false
    }

    showPhonenumber = () => {
        this.state.showPhonenumber ? this.setState({showPhonenumber: false}) : this.setState({showPhonenumber: true})
    }


    render(){
        return(
            <div>
                <div className={classes.wrapper}>
                    <h1>Muokkaa tietoja</h1>
                    <AdminSettings show={this.state.showPhonenumber} click={this.showPhonenumber} functionality='Studion numero' setting='phonenumber' showPhonenumber={this.state.showPhonenumber}/>            
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        token: state.auth.token,
    }
}

const mapDispatchToProps = dispatch => {
    return{
        onChange: (play) => dispatch(actions.changeStatePlay(play))
    }
};

export default connect(mapStateToProps,mapDispatchToProps)(InformationEditor);